<template>
	<div class="content-wrap consult-report">
		<Tabs :value="name">
			<TabPane label="咨询类型统计" name="research-type">
				<ResearchType></ResearchType>
			</TabPane>
			<TabPane label="每日回复量统计" name="daily-response">
				<DailyResponse></DailyResponse>
			</TabPane>
			<TabPane label="工作量统计" name="workload">
				<workload></workload>
			</TabPane>
			<TabPane label="由你负责这个研究吗？" name="whether-responsible">
				<WhetherResponsible></WhetherResponsible>
			</TabPane>
			<TabPane label="在研究中您担任" name="duty">
				<Duty></Duty>
			</TabPane>
			<TabPane
				label="请问您之前是否向CRU咨询过这个研究项的事宜？"
				name="whether-consult"
			>
				<WhetherConsult></WhetherConsult>
			</TabPane>
			<TabPane label="本次咨询的主要内容和方向是什么？" name="provide-service">
				<provideService></provideService>
			</TabPane>
		</Tabs>
	</div>
</template>
<script>
import { mapState } from "vuex"
import ResearchType from "./ConsultingReportChild/ResearchType.vue"
import DailyResponse from "./ConsultingReportChild/DailyResponse.vue"
import Workload from "./ConsultingReportChild/Workload.vue"
import WhetherResponsible from "./ConsultingReportChild/WhetherResponsible.vue"
import Duty from "./ConsultingReportChild/Duty.vue"
import WhetherConsult from "./ConsultingReportChild/WhetherConsult.vue"
import provideService from "./ConsultingReportChild/provideService.vue"

export default {
	data() {
		return {
			name: "research-type"
		}
	},
	components: {
		ResearchType,
		DailyResponse,
		Workload,
		WhetherResponsible,
		Duty,
		WhetherConsult,
		provideService
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 80
		})
	},
	created() {},

	methods: {}
}
</script>
<style lang="less">
.consult-report {
	.option-top {
		padding-bottom: 16px;
	}

	.export {
		float: right;
	}

	.nodata {
		text-align: center;
	}

	.ivu-tabs {
		.ivu-tabs-tabpane {
			position: relative;
		}
	}
}
</style>
