<template>
	<div>
		<div class="option-top">
			<span>选择你想要查询的年份：</span>
			<DatePicker
				type="year"
				style="width: 200px"
				placeholder="请选择年份"
				:transfer="true"
				:clearable="false"
				format="yyyy"
				:value="year"
				@on-change="changeYear"
			>
			</DatePicker>
			<Button
				:size="isXinhua ? 'large' : 'small'"
				type="primary"
				class="export"
				@click="exportHandle"
				>导出
			</Button>
		</div>
		<div :style="`height:${dataGridHeight}px`">
			<Spin v-if="loading" fix></Spin>
			<p
				v-if="!total"
				class="nodata"
				:style="`line-height:${dataGridHeight}px`"
			>
				暂无数据
			</p>
			<v-chart
				v-else
				:forceFit="true"
				:height="dataGridHeight"
				:data="datas"
				:scale="scale"
			>
				<v-tooltip :showTitle="false" />
				<v-legend
					dataKey="key"
					position="left-top"
					:offsetX="200"
					:clickable="false"
				/>
				<v-pie
					position="value"
					color="key"
					:v-style="pieStyle"
					:label="labelConfig"
				/>
				<v-coord type="theta" />
			</v-chart>
		</div>
	</div>
</template>
<script>
import api from "@/api/report/report"
import { mapState } from "vuex"

import DataSet from "@antv/data-set"
import moment from "moment"

const { apiGetResearchType } = api
export default {
	name: "ResearchType",
	data() {
		return {
			scale: [
				{
					dataKey: "percent",
					min: 0,
					formatter: ".0%"
				}
			],
			pieStyle: {
				stroke: "#fff",
				lineWidth: 1
			},
			labelConfig: [
				"percent",
				{
					formatter: (val, item) =>
						`${item.point.key}: ${item.point.value} (${val})`
				}
			],
			year: moment().format("YYYY"),
			datas: null,
			sourceData: [],
			loading: false,
			total: 0
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 136
		}),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		this.getDatas()
	},
	methods: {
		// 图表生成
		createDataSet() {
			const dv = new DataSet.View().source(this.sourceData)
			dv.transform({
				type: "percent",
				field: "value",
				dimension: "key",
				as: "percent"
			})
			this.datas = dv.rows
		},
		// 年份切换
		changeYear(v) {
			this.year = v
			this.getDatas()
		},
		// 获取数据
		async getDatas() {
			this.loading = true
			const res = await apiGetResearchType(this.year)
			if (res) {
				this.total = 0
				res.forEach(el => {
					this.total += el.value
				})
				this.sourceData = this.total ? res : []
				this.createDataSet()
			}
			this.loading = false
		},
		// 导出csv文件
		exportHandle() {
			window.open(
				`${this.$baseUrl}/report/consultation/downloadcsv?token=${this.$store.state.user.token}&type=1&param=${this.year}`
			)
		}
	}
}
</script>
