<template>
	<div>
		<div class="option-top">
			<span>选择你想要查询的年份：</span>
			<DatePicker
				type="year"
				style="width: 200px"
				placeholder="请选择年份"
				:transfer="true"
				:clearable="false"
				format="yyyy"
				:value="year"
				@on-change="changeYear"
			>
			</DatePicker>
			<Button
				:size="isXinhua ? 'large' : 'small'"
				type="primary"
				class="export"
				@click="exportHandle"
				>导出
			</Button>
		</div>
		<div :style="`height:${dataGridHeight}px`">
			<Spin v-if="loading" fix></Spin>
			<p
				v-if="!total"
				class="nodata"
				:style="`line-height:${dataGridHeight}px`"
			>
				暂无数据
			</p>
			<v-chart
				v-else
				:forceFit="true"
				:height="dataGridHeight"
				:padding="[50, 0, 10, 80]"
				:data="datas"
				:scale="scale"
			>
				<v-tooltip :showTitle="false" />
				<v-legend
					dataKey="key"
					position="left-top"
					:offsetX="80"
					:clickable="false"
				/>
				<v-pie
					position="value"
					color="key"
					:v-style="pieStyle"
					:label="labelConfig"
				/>
				<v-coord type="theta" />
			</v-chart>
		</div>
		<Table :columns="columns" :data="sourceData"></Table>
	</div>
</template>
<script>
import api from "@/api/report/report"
import { mapState } from "vuex"
import DataSet from "@antv/data-set"
import moment from "moment"

const { apiGetDuty } = api
export default {
	name: "ResearchType",
	data() {
		return {
			scale: [
				{
					alias: "percent",
					formatter: "%"
				}
			],
			pieStyle: {
				stroke: "#fff",
				lineWidth: 1
			},
			labelConfig: [
				"percent",
				{
					formatter: (val, item) =>
						`${item.point.key}: ${item.point.value} (${(Number(val) * 100).toFixed(2)}%)`
				}
			],
			year: moment().format("YYYY"),
			datas: null,
			sourceData: [],
			columns: [
				{
					title: "选项",
					key: "key"
				},
				{
					title: "咨询数量",
					key: "value"
				},
				{
					title: "占（百分比）",
					key: "percent",
					render: (h, params) => {
						let total = 0
						this.sourceData.forEach(o => {
							total += o.value
						})
						return h(
							"span",
							total ? `${((params.row.value / total) * 100).toFixed(2)}%` : 0
						)
					}
				}
			],
			loading: false,
			total: 0
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 256
		}),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		this.getDatas()
	},
	methods: {
		// 图表生成
		createDataSet() {
			const dv = new DataSet.View().source(this.sourceData)
			dv.transform({
				type: "percent",
				field: "value",
				dimension: "key",
				as: "percent"
			})
			this.datas = dv.rows
		},
		// 筛选年份
		changeYear(v) {
			this.year = v
			this.getDatas()
		},
		// 获取数据
		getDatas() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDuty(this.year)
				if (res) {
					this.total = 0
					res.forEach(el => {
						this.total += el.value
					})
					this.sourceData = this.total ? res : []
					this.createDataSet()
				}
				this.loading = false
			})
		},
		// 导出csv文件
		exportHandle() {
			window.open(
				`${this.$baseUrl}/report/consultation/downloadcsv?token=${this.$store.state.user.token}&type=4&param=${this.year}`
			)
		}
	}
}
</script>
<style lang="less" scoped>
.option-top span {
	padding-left: 8px;
}

.export {
	float: right;
}
</style>
