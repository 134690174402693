<template>
	<div>
		<div class="option-top">
			<span>选择你想要查询的年份：</span>
			<DatePicker
				type="month"
				placeholder="请选择年月"
				style="width: 200px"
				:clearable="false"
				format="yyyy-MM"
				:value="dateTime"
				@on-change="changeDateTime"
			></DatePicker>
			<Button
				:size="isXinhua ? 'large' : 'small'"
				type="primary"
				class="export"
				@click="exportHandle"
				>导出
			</Button>
		</div>
		<div :style="`height:${dataGridHeight}px`">
			<Spin v-if="loading" fix></Spin>
			<p
				v-if="!total"
				class="nodata"
				:style="`line-height:${dataGridHeight}px`"
			>
				暂无数据
			</p>
			<v-chart v-else :forceFit="true" :height="dataGridHeight" :data="datas">
				<v-coord type="rect" direction="LT" />
				<v-tooltip />
				<v-legend />
				<v-axis dataKey="value" position="right" />
				<v-axis dataKey="label" :label="label" />
				<v-bar position="label*value" color="type" :adjust="adjust" />
			</v-chart>
		</div>
	</div>
</template>
<script>
import api from "@/api/report/report"
import { mapState } from "vuex"
import moment from "moment"

const DataSet = require("@antv/data-set")

const { apiGetWorkhour } = api
export default {
	name: "workload",
	data() {
		return {
			loading: false,
			total: 0,
			dateTime: moment().format("YYYY-MM"),
			label: { offset: 12 },
			adjust: [{ type: "dodge", marginRatio: 1 / 32 }],
			datas: null,
			sourceData: [],
			fields: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight,
			cruServiceList: state => state.enumerate.CRU_SERVE
		}),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	watch: {
		cruServiceList(val) {
			if (val && val.length) {
				const arr = val.map(item => item.label)
				this.fields = ["总工时", ...arr]
				this.getDatas()
			}
		}
	},
	mounted() {
		const arr = this.cruServiceList
			? this.cruServiceList.map(item => item.label)
			: []
		this.fields = ["总工时", ...arr]
		this.getDatas()
	},
	methods: {
		// 获取数据
		getDatas() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetWorkhour({ dateTime: this.dateTime })
				if (res) {
					this.total = res.length
					const newArr = []
					if (res.length) {
						res.forEach((item, i) => {
							newArr[i] = {}
							item.forEach(val => {
								const smallArr = val.split(":")
								if (smallArr[0] !== "label") {
									newArr[i][smallArr[0]] = parseFloat(smallArr[1], 10)
								} else {
									newArr[i][smallArr[0]] = smallArr[1]
								}
							})
						})
					}
					this.sourceData = this.total ? newArr : []
					this.total && this.createDataSet()
				}
				this.loading = false
			})
		},
		// 生成图表
		createDataSet() {
			const dv = new DataSet.View().source(this.sourceData)
			dv.transform({
				type: "fold",
				fields: this.fields,
				key: "type",
				value: "value"
			})
			this.datas = dv.rows
		},
		// 年月改变
		changeDateTime(v) {
			this.dateTime = `${v}`
			this.getDatas()
		},
		// 导出csv文件
		exportHandle() {
			window.open(
				`${this.$baseUrl}/report/consultation/workhour/export?token=${this.$store.state.user.token}&dateTime=${this.dateTime}`
			)
		}
	}
}
</script>
<style lang="less" scoped>
.option-top span {
	padding-left: 8px;
}

.export {
	float: right;
}
</style>
