<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="类型">
				<Select
					v-model="searchParams.type"
					:transfer="true"
					placeholder="请选择类型"
				>
					<Option :value="1">入账</Option>
					<Option :value="2">出账</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				v-if="isPermission('btn_project_outlay_mechanism_in_add')"
				type="primary"
				@click="handleAddShow(1)"
				>新增入账</Button
			>
			<Button
				v-if="isPermission('btn_project_outlay_mechanism_out_add')"
				type="primary"
				@click="handleAddShow(2)"
				>新增出账</Button
			>
			<span class="addmoney">
				(
				<span v-if="isPermission('btn_project_outlay_mechanism_in_add')"
					>总入账：{{ totalEntryAmount }}元 &nbsp;</span
				>
				<span v-if="isPermission('btn_project_outlay_mechanism_out_add')"
					>总出账：{{ totalOutAmount }}元</span
				>
				)
			</span>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="information">
				<span>
					{{
						row.information
							? inAccountInfo.find(item => item.value === row.information).label
							: ""
					}}
				</span>
			</template>
		</CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:departList="departList"
			:outlayId="updateId"
			:projectId="projectId"
			:projectInfo="projectInfo"
			:inAccountInfo="inAccountInfo"
			:entry="entry"
			:type="fundsType"
		></Add>
	</div>
</template>

<script>
import api from "@/api/project/funds"
import { mapState } from "vuex"
import config from "@/config/options.config"
import Add from "./Add.vue"
import ConsultingReportVue from "../../../Report/ConsultingReport.vue"

const {
	apiGetFundsListPage,
	deleteFunds,
	apiGetDeparts,
	apiGetFundsProDetail,
	apiGetMoney
} = api

const { inAccountInfo } = config
export default {
	name: "index",
	components: {
		Add
	},
	data() {
		return {
			totalEntryAmount: "",
			totalOutAmount: "",
			addVisible: false,
			inAccountInfo,
			entry: "",
			fundsType: "", // 入账 / 出账
			updateId: "",
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			listData: [],
			columns: [
				{
					title: "类型",
					key: "type",
					minWidth: 160,
					render: (h, params) =>
						h("span", {}, params.row.type === 1 ? "入账" : "出账")
				},
				{
					title: "项目类别",
					key: "projectType",
					minWidth: 140
				},
				{
					title: "项目编号",
					key: "projectNo",
					minWidth: 130
				},
				/* {
          title: '项目名称',
          key: 'projectName',
          minWidth: 120,
        }, */
				{
					title: "项目负责科室",
					key: "department",
					minWidth: 140
				},
				{
					title: "项目负责人",
					key: "piName",
					minWidth: 140
				},
				{
					title: "手机号",
					key: "piMobile",
					minWidth: 130
				},
				{
					title: "申办方",
					key: "smo",
					minWidth: 120
				},
				{
					title: "CRO",
					key: "cro",
					minWidth: 120
				},
				{
					title: "入/出账金额(元)",
					key: "amount",
					minWidth: 140
				},
				{
					title: "入/出账时间",
					key: "arrivalTime",
					minWidth: 120
				},
				{
					title: "入/出账原因",
					key: "reason",
					minWidth: 120
				},
				{
					title: "扣税税费(元)",
					key: "tax",
					minWidth: 120
				},
				{
					title: "扣税税率",
					key: "taxRate",
					minWidth: 120
				},
				{
					title: "入账信息",
					key: "information",
					minWidth: 120,
					slot: "information"
				},
				{
					title: "其他经费用途",
					key: "other",
					minWidth: 120
				},
				{
					title: "开票公司名称",
					key: "invoiceCompany",
					minWidth: 120
				},
				{
					title: "纳税人识别码",
					key: "invoiceTaxNo",
					minWidth: 120
				},
				{
					title: "开票公司地址",
					key: "invoiceAddress",
					minWidth: 120
				},
				{
					title: "开票公司电话",
					key: "invoiceMobile",
					minWidth: 120
				},
				{
					title: "开户银行",
					key: "invoiceBank",
					minWidth: 120
				},
				{
					title: "开户行账号",
					key: "invoiceBankAccount",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 180,
					fixed: "right",
					renderButton: ({ row }) => {
						const btnList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							},
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]

						const isHasAddIn = this.isPermission(
							"btn_project_outlay_mechanism_in_add"
						)
						const isHasAddOut = this.isPermission(
							"btn_project_outlay_mechanism_out_add"
						)

						if (
							(row.type === 1 && !isHasAddIn) ||
							(row.type === 2 && !isHasAddOut)
						) {
							// 入账 / 出账
							// btnList[0] = null;
							btnList[1] = null
							btnList[2] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			loading: false,
			searchParams: {
				type: ""
			},
			oldSearchParams: {
				type: ""
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 220
			}
		}),
		...mapState("permission", ["projectActionPermissions"]),
		projectId() {
			return this.$route.params.projectId
		}
	},
	created() {
		this.initList()
		this.getPublicData()
		this.moneys()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
			// 获取项目基本信息
			this.$asyncDo(async () => {
				const res = await apiGetFundsProDetail(this.projectId)
				if (res) {
					this.projectInfo = res.data || {}
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetFundsListPage(this.projectId, {
					classification: 1,
					type: this.searchParams.type,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		moneys() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetMoney(this.projectId, {
					classification: 1
				})
				if (res) {
					this.totalEntryAmount = res.data.totalEntryAmount
					this.totalOutAmount = res.data.totalOutAmount
				}
				this.loading = false
			})
		},
		// 新增显示
		handleAddShow(type) {
			this.fundsType = type
			this.updateId = ""
			this.entry = "add"
			this.addVisible = true
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
			this.moneys()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.fundsType = row.type
			this.updateId = row.id
			this.entry = "edit"
			this.addVisible = true
			this.moneys()
		},
		// 查看
		handleViewShow({ row }) {
			this.fundsType = row.type
			this.updateId = row.id
			this.entry = "view"
			this.addVisible = true
		},
		// 删除
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await deleteFunds({
					projectId: row.projectId,
					outlayId: row.id
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
					this.moneys()
				} else {
					this.loading = false
				}
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.initList()
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.initList()
		},
		// 重置
		reset() {
			this.searchParams = {
				type: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
				this.moneys()
			})
		},
		isPermission(code) {
			return this.projectActionPermissions.indexOf(code) > -1
		}
	}
}
</script>
<style lang="less">
.addmoney {
	/*margin-left: 210px;*/
	margin-top: -25px;
	color: red;
}
</style>
