<template>
	<div>
		<div class="option-top">
			<span>选择你想要查询的年份：</span>
			<DatePicker
				type="month"
				placeholder="请选择年月"
				style="width: 200px"
				:clearable="false"
				format="yyyy-MM"
				:value="dateTime"
				@on-change="changeDateTime"
			></DatePicker>
			<Button
				:size="isXinhua ? 'large' : 'small'"
				type="primary"
				class="export"
				@click="exportHandle"
				>导出
			</Button>
		</div>
		<div :style="`height:${dataGridHeight}px`">
			<Spin v-if="loading" fix></Spin>
			<p
				v-if="!total"
				class="nodata"
				:style="`line-height:${dataGridHeight}px`"
			>
				暂无数据
			</p>
			<v-chart
				v-else
				:forceFit="true"
				:height="dataGridHeight"
				:data="datas"
				:scale="scale"
			>
				<v-tooltip />
				<v-axis />
				<v-smooth-line position="key*value" color="value" shape="smooth" />
				<v-point position="key*value" color="value" shape="circle" />
			</v-chart>
		</div>
	</div>
</template>
<script>
import api from "@/api/report/report"
import { mapState } from "vuex"
import moment from "moment"

const DataSet = require("@antv/data-set")

const { apiGetDailyResponse } = api
export default {
	name: "ResearchType",
	data() {
		return {
			scale: [
				{
					dataKey: "key",
					min: 0
				}
			],
			pieStyle: {
				stroke: "#fff",
				lineWidth: 1
			},
			labelConfig: [
				"percent",
				{
					formatter: (val, item) => `${item.point.item}: ${val}`
				}
			],
			dateTime: moment().format("YYYY-MM-1"),
			datas: null,
			sourceData: [],
			loading: false,
			total: 0
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 100
		}),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		}
	},
	created() {
		this.getDatas()
	},
	methods: {
		// 生成图表
		createDataSet() {
			const dv = new DataSet.View().source(this.sourceData)
			dv.transform({
				type: "fold",
				fields: ["value"],
				key: "value",
				value: "value"
			})
			this.datas = dv.rows
		},
		// 年月改变
		changeDateTime(v) {
			this.dateTime = `${v}-1`
			this.getDatas()
		},
		// 获取数据
		getDatas() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDailyResponse(this.dateTime)
				if (res) {
					this.total = 0
					res.forEach(el => {
						this.total += el.value
					})
					this.sourceData = this.total ? res : []
					this.createDataSet()
				}
				this.loading = false
			})
		},
		// 导出csv文件
		exportHandle() {
			window.open(
				`${this.$baseUrl}/report/consultation/downloadcsv?token=${this.$store.state.user.token}&type=2&param=${this.dateTime}`
			)
		}
	}
}
</script>
<style lang="less" scoped>
.option-top span {
	padding-left: 8px;
}

.export {
	float: right;
}
</style>
